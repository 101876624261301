<template>
   <div class="page-face">
       <div class='face-header'>
           <div class='header-img'></div>
       </div>
       <div class='face-main'>
           <div class='main-h'>确认{{realName}}本人操作</div>
           <div class='main-text'>请保持正脸在取景框中根据屏幕指示完成识别</div>
           <div class='main-img'></div>  
       </div>
       <div class='footer-btn' @click="goFace">
          开始人脸识别
       </div>
   </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import {routerTag } from '../../utils/index'
import { Toast } from "vant"

export default ({
    setup() {
        const state=reactive({
              faceToken:'',
              faceChannel:'',
              faceReqId:'',
              faceUrl:'',
              realName:''
        })
        //获取token
        const initToken= async ()=>{
            const result= await API.faceToken({ })
            // console.log(result);bizToken
            if(result){
                
                state.faceToken=result.token.bizToken
                state.faceChannel=result.faceChannel
                state.faceReqId=result.token.requestId
                // window.localStorage.setItem('faceStatus','')
                // window.localStorage.setItem('faceMessage','')
            }
           
        }
         //活体识别
        const goFace= async ()=>{
            window.TDAPP.onEvent("人脸识别点击-进入");
            console.log(state.faceChannel,8888)
            if(state.faceChannel=='1'){
                console.log('face++')
                console.log(state.faceToken, state.faceChannel,state.faceReqId,443)
                APP.getFace_UP(state.faceToken, state.faceChannel,state.faceReqId,function(res){
                   console.log(state.faceToken, state.faceChannel,state.faceReqId,444)
                    if(res){
                            state.faceUrl=res
                            applyTokenDo(uploadFile);  
                    }                  
                
                }) 
            }else if(state.faceChannel=='2'){
                 console.log('新诺威尔')
                //新诺威尔
                APP.getFace_UP(state.faceToken, state.faceChannel,state.faceReqId,function(res){
                
                    if(res){
                        // console.log(res,88);
                        state.faceUrl=res.arrLFImage[0]
                        applyTokenDo(uploadFile);  
                    }                  
                
                }) 
            }
           
        
        }
        //上传到oss
        //在没有后端接口的情况下使用
        // var client = new OSS({
        //     region:'oss-cn-hangzhou',
        //     accessKeyId:'LTAI5tCst9tt6pZ7NP5gKrCJ',
        //     accessKeySecret:'lytia7qITEvakQ7bIXSze5kur0tA6p',
        //     bucket:'j-resource-prod',
            
        // });

        // const applyTokenDo = function (func) {
        //     return func(client);
        // };

        //有接口的情况下
        const applyTokenDo =async function (func) {
            //请求后台接口
            await API.OssToken({ }).then(function(result){
                console.log(result,887)
                    var client = new OSS({
                        region:'oss-cn-hangzhou',
                        accessKeyId: result.accessKey,
                        accessKeySecret: result.accessSecret,
                        stsToken: result.securityToken,
                        bucket:'j-resource-prod',
                    });
                    return func(client);
                })
        };
        const getNewFormDate=()=>{
            var date=new Date();
            var year=date.getFullYear();
            var month=date.getMonth()+1;
            var srtDate=date.getDate();
            if(month>=1 && month<=9){
            month='0'+month
            }
            if(srtDate>=1 && srtDate<=9){
            srtDate='0'+srtDate
            }
            var currentdate=year+month+srtDate
            return currentdate
        
        }
        const uploadFile = function (client) {
            // var key='face' //图片的文件目录
            var timestamp = (new Date()).getTime();
            let key = 'CERT/'+ `${getNewFormDate()}/`+timestamp+'.jpg';
            var file=base64ToFile('data:image/jpg;base64,' + state.faceUrl)  
            console.log(file)   
            return client.multipartUpload(key,file).then(function (res) {
                console.log(res,8888)
                state.faceUrl=res.res.requestUrls[0].split('?')[0]
                interFace()
            }).catch((err)=>{
                // APP.Alert(err)
                console.log(err,888)
            })
            
        }
    
    
        const  base64ToFile=(urlData) =>{
            let arr = urlData.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bytes = atob(arr[1]); // 解码base64
            let n = bytes.length;
            let ia = new Uint8Array(n);
            while (n--) {
                ia[n] = bytes.charCodeAt(n);
            }
            return new File([ia], `${new Date().getTime()}.jpg`, { type: mime });
        }
        const interFace=async()=>{
          //上传到oss后拿到的路径
            const faceResult= await API.faceReco({ 
              faceBestImgPath: state.faceUrl, //最佳人脸照片URL face++
              faceFullImgPath:state.faceUrl, //全景照片URL  face++
              faceRandomImgPath: state.faceUrl, //随机照片URL  face++
              faceToken:state.faceToken //令牌  face++
            })
           console.log(faceResult,98)
            if(faceResult.authResult.authState){
                console.log(faceResult,77)
                const baseUrl=routerTag+"baseInfo"
                APP.JUMP_H5(baseUrl,'基础信息')   
            }else{
               console.log(faceResult,882)
               const{message}=faceResult.authResult
               console.log(message,86)
                Toast(message)
                // Toast.fail(message);
                setTimeout(function(){
                    // console.log("Hello world")
                    initToken()
                },2000)
                
                
            }
    
        }
        onMounted(()=>{
        //   APP.IS_SHOW_BACK(false)
            APP.SET_TITLE('人脸识别')
            state.realName= window.localStorage.getItem('authName')
            initToken()
        })
        return{
            ...toRefs(state),
            goFace
            
        }
    },
})
</script>